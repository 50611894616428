
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/org/[org]/ecommerce/[...404]",
      function () {
        return require("private-next-pages/org/[org]/ecommerce/[...404].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/org/[org]/ecommerce/[...404]"])
      });
    }
  