import { ecommerceProps } from '@/shared/server/ecommerceProps';
import { EcommerceGetServerSideProps } from '@/shared/server/types';

import { NOT_FOUND_PAGE_KEY as pageKey } from '../constants';

const getServerSideProps: EcommerceGetServerSideProps = async ({
  req,
  params,
}) =>
  await ecommerceProps({
    req,
    params,
    pageKey,
    locales: {},
  });

export default getServerSideProps;
